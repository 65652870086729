import React, { useState } from 'react';
import {
  Box,
  Button,
  Container,
  ContentLayout,
  Flashbar,
  Header,
  Icon,
  SpaceBetween,
} from '@amzn/awsui-components-react';
import { useParams } from 'react-router';
import { useTranslation } from 'react-i18next';
import { useJamChallengeDetails } from '@/src/store/jam-challenge-details.context';
import { i18nKeys } from '@/src/utils/i18n.utils';
import JamComment from '@/src/components/ui/organisms/MyJams/JamRecentFeedback/JamComment/JamComment';
import { useJamChallengeRecentFeedback } from '@/src/store/jam-challenge-recent-feedback';
import { localLogger } from '@/src/utils/log.utils';
import { useFlashbars } from '@/src/store/flashbar.context';
import { getErrorMessage } from '@/src/utils/errors.utils';
import './JamRecentFeedback.scss';
import { useJamEventDetails } from '@/src/store/jam-event-details.context';
import JamContainer from '../../ui/molecules/JamContainer/JamContainer';
import JamSpinner from '../../common/JamSpinner';
import JamChallengeHeader from '../JamChallengeHeader/JamChallengeHeader';

const JamRecentFeedback = () => {
  const { t } = useTranslation();
  const { addErrorFlashbar, clearFlashbars } = useFlashbars();
  const params: { 0: string; 1: string } = useParams();
  const { selectedJamChallenge } = useJamChallengeDetails();
  const { loadJamChallengeRecentFeedbackData, jamChallengeRecentFeedbackData } = useJamChallengeRecentFeedback();
  const { eventName, event } = useJamEventDetails();
  const [loading, setLoading] = useState(true);

  const getJamChallengeRecentFeedbackData = async () => {
    try {
      clearFlashbars();
      setLoading(true);
      await loadJamChallengeRecentFeedbackData({ eventId: eventName, challengeId: params[1] });
    } catch (e: any) {
      localLogger(e);
      addErrorFlashbar(getErrorMessage(e));
    }
    setLoading(false);
  };

  React.useEffect(() => {
    void getJamChallengeRecentFeedbackData();
  }, [eventName, params[1]]);

  if (loading) {
    return <JamSpinner />;
  }

  const getChallengeTitleFromEventData = () => {
    const challenge = event?.challenges?.length && event.challenges.find((c) => c.id === params[1]);
    return !!challenge ? challenge.title : null;
  };
  const title = selectedJamChallenge?.title ?? getChallengeTitleFromEventData() ?? 'Untitled Jam Challenge';

  return (
    <ContentLayout header={<Header variant="h1">{title}</Header>}>
      <SpaceBetween direction="vertical" size="xxl">
        <JamChallengeHeader hideProperties />
        <Box margin={{ vertical: 'm' }}>
          <SpaceBetween direction="vertical" size="l">
            <Flashbar
              items={[
                {
                  type: 'info',
                  content: t(i18nKeys.myJams.challenges.details.recentFeedback.alert),
                  id: 'recent-feedback-alert',
                },
              ]}
            />
            <Container
              className="comments-wrapper"
              header={
                <Header
                  variant="h1"
                  actions={
                    <Button onClick={() => void getJamChallengeRecentFeedbackData()}>
                      <Icon name="refresh" />
                      <Box margin={{ left: 'xs' }} variant="span" color="text-status-info">
                        {t(i18nKeys.myJams.challenges.details.recentFeedback.buttons.refresh)}
                      </Box>
                    </Button>
                  }>
                  {t(i18nKeys.myJams.challenges.details.recentFeedback.title)}
                </Header>
              }>
              {jamChallengeRecentFeedbackData?.length ? (
                <Box className="comments-layout">
                  {jamChallengeRecentFeedbackData?.map((feedback) => (
                    <JamComment key={feedback.notes} review={feedback} />
                  ))}
                </Box>
              ) : (
                <JamContainer>{t(i18nKeys.myJams.challenges.details.recentFeedback.emptyList)}</JamContainer>
              )}
            </Container>
          </SpaceBetween>
        </Box>
      </SpaceBetween>
    </ContentLayout>
  );
};

export default JamRecentFeedback;
