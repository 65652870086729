import React, { useEffect, useState } from 'react';
import { Badge, Box, Button, ColumnLayout, Icon, SpaceBetween } from '@amzn/awsui-components-react';
import { useTranslation } from 'react-i18next';
import { i18nKeys } from '@/src/utils/i18n.utils';
import ClueDetailsModal from '@/src/components/ui/molecules/MyJams/JamChallengeDetails/JamTask/Modals/ClueDetailsModal';
import { useParams } from 'react-router-dom';
import { Markdown } from '@/src/components/common/Markdown';
import "./ClueContainer.scss";

interface ClueContainerProps {
  title: string;
  description?: string;
  penalty: number;
  btnText: string;
  order: number;
  used?: boolean;
  isFacilitator: boolean;
  disableClueBtn?: boolean;
}

const ClueContainer: React.FC<ClueContainerProps> = ({
  title,
  penalty,
  btnText,
  order,
  description,
  isFacilitator,
  used = false,
  disableClueBtn = false
}) => {
  const { t } = useTranslation();
  const params: { 0: string; 1: string; 2: string } = useParams();
  const [visible, setVisible] = useState(false);
  const [showClue, setShowClue] = useState(used);
  const [showClueContent, setShowClueContent] = useState(false);

  const taskId = params[2];

  useEffect(() => {
    setShowClue(used);
  }, [used]);

  useEffect(() => {
    setShowClueContent(false);
  }, [taskId]);

  const getBtnColor = () => !disableClueBtn ? "text-status-info" : "inherit";
  return (
    <React.Fragment>
      <ColumnLayout columns={2}>
        <Box variant="div">
          <Box variant="h3" margin={{ bottom: 'xs' }}>
            {title}
          </Box>
          <Badge color="blue">
            {t(i18nKeys.myJams.challenges.details.task.clue.penaltyPoints, { penaltyPoints: penalty })}
          </Badge>
        </Box>
        {showClue ? (
          <Box variant="div" textAlign="center" float="right">
            <Button onClick={() => setShowClueContent(!showClueContent)}>
              <Box variant="span" color={getBtnColor()}>
                {showClueContent ? t(i18nKeys.myJams.challenges.details.task.clue.hideClue) : t(i18nKeys.myJams.challenges.details.task.clue.showClue)}
              </Box>
            </Button>
          </Box>
        ) : (
          <Box variant="div" float="right">
            <SpaceBetween direction="horizontal" size="s">
              <Button onClick={() => setVisible(!visible)} disabled={disableClueBtn}>
                <Icon name={'lock-private'} />
                <Box variant="span" color={getBtnColor()} padding={{ left: 'xs' }}>
                  {btnText}
                </Box>
              </Button>
              {isFacilitator && (
                <Button onClick={() => setShowClueContent((prev) => !prev)}>{showClueContent ? 'Hide' : 'Show'}</Button>
              )}
            </SpaceBetween>
          </Box>
        )}
        <ClueDetailsModal
          value={visible}
          setValue={setVisible}
          setShowClue={setShowClue}
          order={order}
          penalty={penalty}
        />
      </ColumnLayout>
      
        {showClueContent && 
          <div className='clues-container__description-wrapper'>
            <Markdown content={description || ''}>{description || ''}</Markdown>
          </div>
        }
    </React.Fragment>
  );
};

export default ClueContainer;
