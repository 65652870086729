import { Header } from '@amzn/awsui-components-react';
import * as React from 'react';
import { ReactNode } from 'react';

interface HelpPanelProps {
  title: ReactNode;
}

export const HelpPanel: React.FC<HelpPanelProps> = ({ title, children }) => {
  return (
    <div className="awsui-util-help-panel" style={{ padding: '28px', paddingTop: '40px', paddingBottom: '60px' }}>
      <div className="awsui-util-help-panel-header">
        <Header variant="h3">{title}</Header>
      </div>
      {children}
    </div>
  );
};
