import { useLocation, useRouteMatch } from 'react-router-dom';
import { useUser } from '../store/user.context';
import { RoutePath } from '../RoutePath';
import { JAM_EVENT_DETAILS_ROUTES, faqRoute } from '../routes';
import { jamChallengesRegex } from '../utils/myjam.utils';
import { useJamLeaderboard } from '../store/jam-leaderboard.context';
import { useJamEventDetails } from '../store/jam-event-details.context';
import { useCampaigns } from '../store/campaigns.context';

export const useSideNavBreadCrumbVisibility = (): { isSideNavVisible: boolean; isBreadCrumbVisible: boolean } => {
  const location = useLocation();
  const { user } = useUser();
  const { pathname } = location;
  const { isPresenterMode } = useJamLeaderboard();
  const { eventSlug, event } = useJamEventDetails();
  const { showCampaignRules, campaignStarted, campaignAttempt } = useCampaigns();
  const jamOnboardingRoute = useRouteMatch(RoutePath.JAM_EVENT_ONBOARDING);
  const isSupportUser = !!event?.supportUser;
  const isCampaign = event?.type === 'CAMPAIGN_GROUP';

  // Determines whether or not to hide the
  // navBar and breadcrumbs for a campaign.
  // This is used to hide these while the campaign
  // rules pages is open
  const hideForCampaign = () => {
    if (!isCampaign) {
      return false;
    }

    if (isSupportUser || user?.isSuperAdmin || user?.isEventAdmin) {
      return false;
    }

    if (event.ended) {
      return false;
    }

    return !campaignAttempt || campaignAttempt?.attemptNumber === 0 || !campaignStarted || showCampaignRules;
  };

  const shouldHideForCampaign = hideForCampaign();

  /* BreadCrumb Visibility Logic starts */

  let isBreadCrumbVisible = true;

  const excludedBreadCrumbExactRoutes = [RoutePath.ROOT, RoutePath.MY_JAMS];
  if (
    // pathname.includes('/challenges') || // commenting this as we need breadcrumb in my-jam event routes as per ticket JAM-10304
    (!jamChallengesRegex.test(pathname) && pathname !== RoutePath.PUBLIC_EVENTS) ||
    // jamChallengesRegexForBreadCrumb.test(pathname) || // commenting this as we need breadcrumb in my-jam event routes as per ticket JAM-10304
    pathname.includes(RoutePath.CATALOG_DETAIL) ||
    // pathname.includes('leaderboard') || // commenting this as we need breadcrumb in my-jam event routes as per ticket JAM-10304
    pathname.includes('team/chat') ||
    excludedBreadCrumbExactRoutes.includes(pathname as RoutePath) ||
    pathname.includes(JAM_EVENT_DETAILS_ROUTES.TeamInfo.resolve([eventSlug, ''])) ||
    shouldHideForCampaign ||
    isPresenterMode
  ) {
    isBreadCrumbVisible = false;
  }

  /* BreadCrumb Visibility Logic ends */

  /* SideNav Visibility Logic starts */

  let isSideNavVisible = true;

  const excludedSideNavExactRoutes = [
    RoutePath.ROOT,
    RoutePath.SIGNOUT,
    RoutePath.JAM_404,
    RoutePath.JAM_403,
    RoutePath.JAM_500,
    RoutePath.MY_JAMS,
    RoutePath.MY_JAMS_ACTIVE,
    RoutePath.MY_JAMS_PAST,
    RoutePath.MY_JAMS_UPCOMING,
    RoutePath.JOIN_AN_EVENT,
    RoutePath.PUBLIC_EVENTS,
    RoutePath.USER_PROFILE_CREATE,
    RoutePath.NEW_CHALLENGE,
    RoutePath.NEW_CHALLENGE_SET,
    RoutePath.EVENT_CATALOG_TEMPLATES,
    RoutePath.CHALLENGES_SNIPPETS,
    RoutePath.SSH_ACCESS,
  ];

  if (
    (!user && !(location.pathname.includes(faqRoute.path) || pathname.includes(RoutePath.CATALOG))) ||
    pathname.includes(RoutePath.CATALOG_DETAIL) ||
    pathname.includes('team/chat') ||
    excludedSideNavExactRoutes.includes(pathname as RoutePath) ||
    pathname.includes(JAM_EVENT_DETAILS_ROUTES.TeamInfo.resolve([eventSlug, ''])) ||
    pathname.includes(RoutePath.PUBLIC_EVENTS) ||
    pathname.includes(RoutePath.USER_PROFILE_CREATE) ||
    pathname.includes(RoutePath.CHALLENGE_RESOURCES) ||
    pathname.includes(RoutePath.SHOW_I_AM) ||
    isPresenterMode ||
    jamOnboardingRoute ||
    JAM_EVENT_DETAILS_ROUTES.Report.resolve(eventSlug) === pathname ||
    shouldHideForCampaign
  ) {
    isSideNavVisible = false;
  }

  /* SideNav Visibility Logic ends */
  return { isSideNavVisible, isBreadCrumbVisible };
};
