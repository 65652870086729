/* eslint-disable */
const DEFAULT_EASY_CHALLENGE_SCORE: number = 80;
const DEFAULT_MEDIUM_CHALLENGE_SCORE: number = 150;
const DEFAULT_HARD_CHALLENGE_SCORE: number = 200;
const DEFAULT_CLUE_1_PENALTY_PERCENT: number = 10;
const DEFAULT_CLUE_2_PENALTY_PERCENT: number = 13;
const DEFAULT_CLUE_3_PENALTY_PERCENT: number = 15;
const NEW_ITEM_RESERVED_NAME: string = "new";
const SELECT_OPTION_NONE: string = '__NONE__';
const CHALLENGE_BOARD_MIN_ROWS: number = 2;
const CHALLENGE_BOARD_MAX_ROWS: number = 8;
const CHALLENGE_BOARD_MIN_COLS: number = 2;
const CHALLENGE_BOARD_MAX_COLS: number = 8;


/**
 * How often the Challenge summary and Talk info views in Game UI should check
 * for lab deployment status
 */
const CHALLENGE_LAB_CHECK_INTERVAL_MS = 30000;

/**
 * Query parameter holding an event or campaign's code. Formerly `code`.
 */
const EVENT_CODE_QUERY_PARAM: string = "eventCode";

/**
 * Query parameter used with an event to indicate that the event is designated
 * for AWS SkillBuilder users.
 */
const SKILL_BUILDER_QUERY_PARAM: string = "skillBuilder";

/**
 * Query parameter used in login into Jam.
 */
const IDP_QUERY_PARAM: string = "idp";

/**
 * Training and certification (T&C) IdentityProvider.
 */
const IDP_TRAINING_AND_CERTIFICATION: string = "tc";

/**
 * AmazonFederate IdentityProvider.
 */
const IDP_AMAZON_FEDERATE: string = "AmazonFederate";

/** Event onboarding popup modal*/

const DONT_SHOW_JAM_EVENT_ONBOARDING_POPUP_KEY :string = "dont-show-jam-event-onboarding-popup"
// noinspection JSUnusedGlobalSymbols
export const JamConstants = {
  DEFAULT_EASY_CHALLENGE_SCORE,
  DEFAULT_MEDIUM_CHALLENGE_SCORE,
  DEFAULT_HARD_CHALLENGE_SCORE,
  DEFAULT_CLUE_1_PENALTY_PERCENT,
  DEFAULT_CLUE_2_PENALTY_PERCENT,
  DEFAULT_CLUE_3_PENALTY_PERCENT,
  NEW_ITEM_RESERVED_NAME,
  SELECT_OPTION_NONE,
  CHALLENGE_BOARD_MIN_ROWS,
  CHALLENGE_BOARD_MAX_ROWS,
  CHALLENGE_BOARD_MIN_COLS,
  CHALLENGE_BOARD_MAX_COLS,
  EVENT_CODE_QUERY_PARAM,
  SKILL_BUILDER_QUERY_PARAM,
  IDP_QUERY_PARAM,
  IDP_TRAINING_AND_CERTIFICATION,
  IDP_AMAZON_FEDERATE,
  DONT_SHOW_JAM_EVENT_ONBOARDING_POPUP_KEY,
  CHALLENGE_LAB_CHECK_INTERVAL_MS
};
