import React, { useEffect, useState } from 'react';
import { useHistory } from 'react-router-dom';
import { Box, ContentLayout, Header, SpaceBetween } from '@amzn/awsui-components-react';
import JamChallengeOverview from '../../ui/molecules/MyJams/JamChallengeDetails/JamOverview/JamChallengeOverview';
import TaskDetails from '../../ui/molecules/MyJams/JamChallengeDetails/JamOverview/TaskDetails';
import { i18nKeys } from '@/src/utils/i18n.utils';
import { useTranslation } from 'react-i18next';
import { useJamChallengeDetails } from '@/src/store/jam-challenge-details.context';
import { useParams } from 'react-router';
import './JamChallengeDetails.scss';
import { LoadingBar } from '../../common/LoadingBar';
import { useJamEventDetails } from '@/src/store/jam-event-details.context';
import { useFlashbars } from '@/src/store/flashbar.context';
import JamChallengeHeader from '../JamChallengeHeader/JamChallengeHeader';
import { useJamChallenge } from '@/src/store/jam-challenge.context';
import { LoggingService } from '@/src/utils/logging-service.utils';
import {JAM_EVENT_DETAILS_ROUTES} from '@/src/routes';
import { JamConstants } from '@/src/constants/shared/jam-constants';

const JamChallengeDetails: React.FC = () => {
  const { t } = useTranslation();
  const { eventName, event } = useJamEventDetails();
  const params: { 0: string; 1: string } = useParams();
  const { eventFeedbackData, loadJamChallengeFeedbackData } = useJamChallenge();
  const {
    loadJamChallengeDetails,
    challengeProgress,
    selectedJamChallenge,
    loadChallengeSupportDetails,
    loadJamChallengeQueueInfo,
    showChallengeStatusProgress,
    loadTeamProperties,
  } = useJamChallengeDetails();
  const { clearFlashbars } = useFlashbars();
  const [isLoading, setIsLoading] = useState(true);
  const history = useHistory();

  const isFacilitator = !!event?.facilitator;
  const isLocked = !!selectedJamChallenge?.locked;
  const isStarted = challengeProgress?.challengeId === selectedJamChallenge?.id && !!challengeProgress?.started;

  const initialize = async () => {
    try {
      clearFlashbars();
      setIsLoading(true);

      // load challenge only for empty selectedChallenge or new request
      if (!selectedJamChallenge || selectedJamChallenge?.id !== params[1]) {
        await loadJamChallengeDetails({ id: eventName, challengeId: params[1] });
        void loadJamChallengeFeedbackData({ id: eventName });
      }

      // if user is facilitator load clues
      if (isFacilitator) {
        void loadChallengeSupportDetails(eventName, params[1]);
      }
    } catch (e) {
      LoggingService.debug('Error :', e);
    }
    setIsLoading(false);
  };

  useEffect(() => {
    void loadJamChallengeDetails({ id: eventName, challengeId: params[1] });
  }, [event]);

  useEffect(() => {
    void initialize();
  }, [params[1]]);

  useEffect(() => {
    if (!isLocked && isStarted) {
      void loadJamChallengeQueueInfo(eventName, params[1]);
    }
  }, [isLocked, isStarted]);

  const getChallengeFeedbackUrl = (challengeId: string) => {
    return `${JAM_EVENT_DETAILS_ROUTES.Feedback.resolve(eventName)}?challengeId=${challengeId}`;
  };

  useEffect(() => {
    // if challenge is completed and no feedback found, then redirect to feedback page
    if (
      challengeProgress?.challengeId === params[1] &&
      challengeProgress?.completed &&
      (!eventFeedbackData?.challengeFeedback || !Object.keys(eventFeedbackData.challengeFeedback).includes(params[1]))
    ) {
      const feedbackUrl = getChallengeFeedbackUrl(challengeProgress.challengeId);
      history.push(feedbackUrl);
    }
  }, [challengeProgress, eventFeedbackData]);

  useEffect(() => {
    if (!showChallengeStatusProgress) {
      return;
    }
    const interval = setInterval(() => void loadTeamProperties(eventName, params[1]), JamConstants.CHALLENGE_LAB_CHECK_INTERVAL_MS);
    return () => {
      clearInterval(interval);
    };
  }, [showChallengeStatusProgress]);

  if (isLoading) {
    return <LoadingBar />;
  }

  if (!selectedJamChallenge) {
    return <JamChallengeHeader hideProperties hideHeader />;
  }

  return (
    <ContentLayout
      header={
        <Header variant="h1" description={t(i18nKeys.myJams.challenges.subtitle)}>
          {selectedJamChallenge?.title.length > 50
            ? `${selectedJamChallenge.title.slice(0, 50)}...`
            : selectedJamChallenge?.title}
        </Header>
      }>
      <SpaceBetween direction="vertical" size="xxl">
        <JamChallengeHeader hideProperties />
        {selectedJamChallenge?.learningOutcome?.summary && (
          <Box>
            <Box variant="h1" margin={{ bottom: 'm' }}>
              {t(i18nKeys.myJams.challenges.details.overview.title)}
            </Box>
            <JamChallengeOverview />
          </Box>
        )}
        <Box>
          <Box variant="h2">{t(i18nKeys.myJams.challenges.details.overview.challengeProgress)}</Box>
          <Box variant="p" margin={{ bottom: 'm' }}>
            {t(i18nKeys.myJams.challenges.details.overview.selectTaskToBeginChallenge)}
          </Box>
          <TaskDetails />
        </Box>
      </SpaceBetween>
    </ContentLayout>
  );
};

export default JamChallengeDetails;
